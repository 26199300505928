import React from "react";
import Meta from "./../components/Meta";
import PricingSection from "./../components/PricingSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../components/NewsletterSection";
import analytics from "./../util/analytics";
import GoogleTag from '../components/GoogleTag';
import HelpCrunchGoogleTag from '../components/HelpCrunchGoogleTag.jsx'
import LandingPanel1v4 from "../LandingComponents/LandingPanel1v4.jsx";
import LandingPanel2v4 from "../LandingComponents/LandingPanel2v4.jsx";
import StatsComponent from "../LandingComponents/StatsComponentVideo.jsx";
import ReturningCustomer from "../LandingComponents/ReturningCustomer.jsx";

function IndexPage(props) {
  React.useEffect(() => {
    analytics.page();
  }, []);

  return (
    <>
      <GoogleTag />
      <HelpCrunchGoogleTag />
      <Meta />
      <ReturningCustomer />
      <LandingPanel1v4 />
      <StatsComponent />
      
      <LandingPanel2v4 />
    
      <PricingSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Standard Plan Pricing"
        subtitle="One Simple Plan, includes a 7-day free trial."
      />
      {/* <TestimonialsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      /> */}
      <NewsletterSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="We're constantly adding new features, new stats, and new sports. Sign up to be the first to know!"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
    </>
  );
}

export default IndexPage;
