import React from "react";
import { Grid, Typography, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import monitor from "./Monitor.png";
import degenLogo from "./degenLogo.jpg";
import ListenToDegenerates from "./ListenToDegenerates";
import { Link } from "../util/router";
import { useAuth } from "../util/auth.js";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: 0,
    maxWidth: 1200,
    margin: "0 auto",
  },
  // For the right column, apply a minWidth only on medium screens and up.
  rightColumn: {
    width: "100%",
    margin: "0 auto",
    [theme.breakpoints.up("sm")]: {
      minWidth: 500,
    },
  },
  videoSection: {
    position: "relative",
    paddingBottom: "56.3%", // maintains 16:9 aspect ratio
    height: 0,
    "& iframe": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      border: "none",
      borderRadius: 15,
      boxShadow: "0 3px 5px rgba(0,0,0,0.3)",
    },
  },
  ulList: {
    listStyle: "disc",
    paddingLeft: theme.spacing(5),
    margin: 0,
  },
  nestedList: {
    listStyle: "disc",
    paddingLeft: theme.spacing(5),
    margin: 0,
  },
  // Container for the bottom section (button, logo, text)
  buttonContainer: {
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    marginTop: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
  },
  // CTA button styling.
  ctaButton: {
    backgroundColor: "#f15a29",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#d44c20",
    },
  },
  degenLogo: {
    width: "100%",
    maxWidth: 400,
    margin: "0 auto",
    borderRadius: theme.shape.borderRadius,
    display: "block",
  },
  highlightText: {
    color: "#FFA500",
  },
}));

// Note: we now call useStyles inside the component so the hook is available.
const KeyFeaturesList = () => {
  const classes = useStyles();
  return (
    <Box component="ul" className={classes.ulList}>
      <li>Select a league</li>
      <li>Pick a game</li>
      <li>Choose stats to view</li>
      <li>Instant reports</li>
      <li>
        Season stats and trends
        <Box component="ul" className={classes.nestedList}>
          <li>Season Totals, Recent Games</li>
          <li>As Home/Away, Fave/Dog, Conference Splits</li>
          <li>Total, Average Per Game, Normed and Z-Score Views</li>
          <li>Odds History for US Bookmakers</li>
          <li>Charts, Injuries, Trades and News.</li>
        </Box>
      </li>
    </Box>
  );
};

const VideoSection = () => {
  const classes = useStyles();
  return (
    <Box className={classes.videoSection}>
      <iframe
        src="https://www.youtube.com/embed/jrHmLQ3muzo?si=69TkRYm9yg59DW0S&mute=1"
        title="Sports Betting Dashboard Demo"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </Box>
  );
};

const LandingPanel1v4 = ({ campaign }) => {
  const auth = useAuth();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container spacing={4}>
        <Grid
          container
          item
          xs={12}
          spacing={4}
          alignItems="center"
          justifyContent="flex-start"
        >
          {/* Left Column */}
          <Grid item xs={12} sm={5}>
            <Typography variant="h3" align="right">
              MLB, NFL, NBA
            </Typography>
            <Typography variant="h3" align="right">
              NCAA Football & Basketball
            </Typography>
            <KeyFeaturesList />
          </Grid>

          {/* Right Column */}
          <Grid item xs={12} sm={7} className={classes.rightColumn}>
            <VideoSection />
          
          </Grid>
            <Button
              component={Link}
              to={
                auth.user
                  ? `/purchase/$standard`
                  : `/auth/signup?next=/purchase/standard`
              }
              size="large"
              variant="contained"
              className={classes.ctaButton}
            >
              Try StatFactory.io
            </Button>
        </Grid>

        {/* Bottom Section: CTA Button, Logo, and Discount Info */}
        <Grid container spacing={2} className={classes.buttonContainer}>
          <Grid item>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box
              component="img"
              src={degenLogo}
              alt="Degenerates"
              className={classes.degenLogo}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography variant="h4">
              Welcome{" "}
              <span className={classes.highlightText}>DEGENERATES</span>!
            </Typography>
            <Typography variant="h5">
              To claim the Kendall and Hammer StatFactory.io discount, use the
              code:{" "}
              <strong>
                <span className={classes.highlightText}>DEGENERATES</span>
              </strong>
            </Typography>
            <ListenToDegenerates />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LandingPanel1v4;
