import React from "react";
import { Button, Box } from "@material-ui/core";
import { useAuth } from "../util/auth.js";
import { Link } from "../util/router";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: 600,
    width: "100%",
    margin: "auto",
    padding: theme.spacing(2),
  },
  button: {
    textTransform: "none",
    backgroundColor: "#3f51b5",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2c387e",
    },
  },
}));

const ReturningCustomer = () => {
  const auth = useAuth();
  const classes = useStyles();

  if (!auth.user) return null;

  return (
    <Box className={classes.root}>
      <Button
        component={Link}
        to="/dashboard"
        variant="contained"
        size="large"
        className={classes.button}
      >
        Go To My Dashboard
      </Button>
    </Box>
  );
};

export default ReturningCustomer;
